var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
import VSelect from "vue-select";
import { getComboboxItems } from "@/services/mainRadComboBox";
import { createPopper } from "@popperjs/core";
let DataGridCell = class DataGridCell extends Vue {
    constructor() {
        super(...arguments);
        this.cellValue = null;
        this.options = [];
        this.placement = "top";
        this.callback = false;
    }
    get column() {
        return this.columns[this.name];
    }
    get selectedWindow() {
        return this.$store.getters.selectedWindow;
    }
    get requestSubject() {
        return this.selectedWindow.output.Request.Subject;
    }
    valueChanged(value) {
        return value;
    }
    rowValuesChanged(value) {
        this.cellValue = {
            Text: value.Description,
            Value: value.Value,
        };
    }
    created() {
        if (this.column.Dropdown.Items?.length > 0) {
            this.options = this.column.Dropdown.Items;
        }
        else {
            this.callback = true;
        }
        if (this.value.Value) {
            this.cellValue = {
                Text: this.value.Description,
                Value: this.value.Value,
            };
        }
    }
    withPopper(dropdownList, component, { width }) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width;
        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
            placement: this.placement,
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, -1],
                    },
                },
                {
                    name: "toggleClass",
                    enabled: true,
                    phase: "write",
                    fn({ state }) {
                        component.$el.classList.toggle("drop-up", state.placement === "top");
                    },
                },
            ],
        });
        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy();
    }
    cellValueChanged(newVal) {
        this.valueChanged(newVal);
    }
    async getOptions() {
        if (!this.callback)
            return;
        const primaryKeys = {};
        // If selectbox has criteria
        if (this.column.Dropdown.ExtraKeys) {
            primaryKeys[this.column.Dropdown.ExtraKeys[0]] = this.row[this.column.Dropdown.ExtraKeys[0]].Value;
        }
        const dropdownItems = await getComboboxItems({
            columnName: this.column.Dropdown.ColumnName,
            tableName: this.column.Dropdown.TableName,
            subject: this.requestSubject,
            primaryKeys,
        });
        this.options = dropdownItems;
    }
};
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "value", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "name", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "columns", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "row", void 0);
__decorate([
    Emit()
], DataGridCell.prototype, "valueChanged", null);
__decorate([
    Watch("value")
], DataGridCell.prototype, "rowValuesChanged", null);
DataGridCell = __decorate([
    Component({
        components: { VSelect },
    })
], DataGridCell);
export default DataGridCell;
